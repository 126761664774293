<template>
  <div class="mt-4 row pm-container card p-4">
    <div class="col">
      <h3>Deine Daten</h3>
      <label for="inputName" class="form-label">Name</label>
      <div class="input-group">
        <input v-model="name" type="text" id="inputName" class="form-control" aria-describedby="inputNameHelp">
        <button @click="changeName" class="btn btn-primary">Namen ändern</button>
      </div>
      <div id="inputNameHelp" class="form-text">
        Ihren Namen benötigen wir, damit wir Sie persönlich addressieren können ☺️
      </div>

      <label for="inputEmail" class="mt-4 form-label">Email</label>
      <div class="input-group">
        <input v-model="email" disabled type="text" id="inputEmail" class="form-control"
               aria-describedby="inputEmailHelp">
        <button @click="showChangeEmailModal = true" class="btn btn-primary">Email ändern</button>
      </div>
      <div id="inputEmailHelp" class="form-text">
        Ihre Email verwenden wir um Ihnen News, Angebote oder ein neues Passwort zu schicken, falls Sie es mal vergessen
        sollten 🙃
      </div>

      <!--      <div class="form-check mt-4">-->
      <!--        <input class="form-check-input" type="checkbox" id="gridCheck1" checked>-->
      <!--        <label class="form-check-label" for="gridCheck1">-->
      <!--          Ich möchte benachrichtigt werden wenn neue Videos erscheinen.-->
      <!--        </label>-->
      <!--      </div>-->

      <!--      <div class="form-check mt-1">-->
      <!--        <input class="form-check-input" type="checkbox" id="gridCheck1" checked>-->
      <!--        <label class="form-check-label" for="gridCheck1">-->
      <!--          Ich möchte über neue Seminare benachrichtigt werden.-->
      <!--        </label>-->
      <!--      </div>-->

      <!--      <div class="form-check mt-1">-->
      <!--        <input class="form-check-input" type="checkbox" id="gridCheck1" checked>-->
      <!--        <label class="form-check-label" for="gridCheck1">-->
      <!--          Ich möchte ganz viele Emails bekommen.-->
      <!--        </label>-->
      <!--      </div>-->

      <label class="mt-4 form-label">Passwort</label>
      <div class="input-group">

        <button @click="showChangePasswordModal = true" class="btn btn-primary">Passwort ändern</button>
      </div>

      <Modal v-if="showChangePasswordModal">
        <template v-slot:header>
          <h4>Passwort ändern</h4>
        </template>
        <template v-slot:body>
          <label class="form-label">Altes Passwort</label>
          <div class="input-group">
            <input v-model="oldPassword" type="password" class="form-control">
          </div>
          <label class="form-label">Neues Passwort</label>
          <div class="input-group">
            <input v-model="newPassword" type="password" class="form-control">
          </div>
        </template>
        <template v-slot:footer>
          <button @click="showChangePasswordModal = false" class="btn btn-primary">Abbrechen</button>
          <button @click="changePassword()" class="btn btn-success">Passwort ändern</button>
        </template>
      </Modal>

      <Modal v-if="showChangeEmailModal">
        <template v-slot:header>
          <h4>Email ändern</h4>
        </template>
        <template v-slot:body>
          <label class="form-label">Neue Email</label>
          <div class="input-group">
            <input v-model="newEmail" type="email" class="form-control">
          </div>
        </template>
        <template v-slot:footer>
          <button @click="showChangeEmailModal = false" class="btn btn-primary">Abbrechen</button>
          <button @click="changeEmail()" class="btn btn-success">Email ändern</button>
        </template>
      </Modal>

      <div id="inputPasswordHelp" class="form-text">
        Ihr persönliches Passwort benötigen Sie um sich auf dieser Plattform anmelden zu können 🔐
      </div>

      <hr>
      <div class="form-text mt-4">
        Hier können Sie Ihr Konto löschen. Achtung: Ihre Daten werden vollständig gelöscht und Sie haben
        danach keinen
        Zugriff mehr auf Videos oder Hautanalyse-Ergebnisse.
      </div>
      <button class="btn btn-danger mt-2 mb-4">Konto löschen</button>
    </div>
  </div>
</template>

<script>
import Modal from "../components/Modal";

export default {
  components: {Modal},
  data() {
    return {
      password: "",
      name: "",
      email: "",

      showChangeEmailModal: false,
      newEmail: "",

      showChangePasswordModal: false,
      oldPassword: '',
      newPassword: ''
    }
  },

  name: "Konto",

  mounted() {
    this.$store.dispatch("me/me")
        .then(data => {
          this.name = data.name
          this.email = data.email
        })
  },

  methods: {
    changeName() {
      this.$store.dispatch("me/changeName", {name: this.name})
          .then(() => alert("Geändert!"))
          .catch(() => alert("Fehler"))
    },

    changePassword() {
      this.$store.dispatch("me/changePassword", {oldPassword: this.oldPassword, newPassword: this.newPassword})
          .then(() => {
            alert("Geändert!");
            this.newPassword = ''
            this.oldPassword = ''
            this.showChangePasswordModal = false
          })
          .catch(() => alert("Fehler"))
    },

    changeEmail() {
      this.$store.dispatch("me/changeemail", {email: this.newEmail})
          .then(() => alert("Geändert!"))
          .catch(() => alert("Fehler"))
    }
  }
}
</script>

<style scoped>

</style>